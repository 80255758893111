import styled from 'styled-components';
import FooterImage from 'common/assets/image/hosting/footer-bg.png';
import { themeGet } from '@styled-system/theme-get';

const FooterWrapper = styled.footer`
  position: relative;
  background: #fff;
  overflow: hidden;
  padding: 15px 0 40px 0;

  .footer_container {
    background-repeat: no-repeat;
    background-position: center 50px;
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
    @media (min-width: 576px) {
      background-image: url(${FooterImage});
    }
    @media (max-width: 990px) {
      padding-bottom: 40px;
    }
    @media (max-width: 767px) {
      padding-bottom: 0px;
    }
  }
`;

const List = styled.ul``;

const ListItem = styled.li`
  a {
    color: rgba(52, 61, 72, 0.8);
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: #343d48;
    }
  }
`;

export const MenuArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  @media only screen and (max-width: 1366px) {
    padding-top: 50px;
  }
  > a {
    margin-right: 0;
  }
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 37px;
  margin-bottom: 15px;
  @media only screen and (max-width: 667px) {
    margin-top: 30px;
  }
`;

export const MenuItem = styled.li`
  margin: 0 18px;
  @media only screen and (max-width: 667px) {
    margin: 5px 15px;
  }
  a {
    color: ${themeGet('colors.black', '#090f15')};
    transition: all 0.3s ease;
    &:hover {
      color: rgba(9, 15, 21, 0.7);
    }
  }
`;

export const SocialList = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 30px;

  li {
    margin-right: 30px;
    @media only screen and (max-width: 991px) {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: ${themeGet('colors.lightText', '#7E7E7E')};
      position: relative;
      i {
        position: relative;
        z-index: 1;

        svg {
          width: 15px;
          height: auto;
        }
      }

      &:hover {
        color: ${themeGet('colors.primary', '#FDEF00')};
      }
    }
  }
`;

export { List, ListItem };

export default FooterWrapper;
