import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { facebook } from 'react-icons-kit/fa/facebook';
import { twitter } from 'react-icons-kit/fa/twitter';
import { instagram } from 'react-icons-kit/fa/instagram';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Logo from 'common/components/UIElements/Logo';
import Container from 'common/components/UI/Container';
import ContactSections from '../Contact';
import FooterWrapper, {
  List,
  ListItem,
  MenuArea,
  SocialList,
  Menu,
  MenuItem,
} from './footer.style';

import LogoImage from 'common/assets/image/saasClassic/logo.png';

const socialLinks = [
  {
    id: 1,
    icon: <Icon icon={facebook} />,
    name: 'facebook',
    link: '#',
  },
  {
    id: 2,
    icon: <Icon icon={instagram} />,
    name: 'instagram',
    link: '#',
  },
  {
    id: 3,
    icon: <Icon icon={twitter} />,
    name: 'twitter',
    link: '#',
  },
];

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasClassicJson {
        FOOTER_WIDGET {
          title
          menuItems {
            text
            url
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container className="footer_container">
        {/* <ContactSections /> */}
        <MenuArea>
          <Logo
            href="#"
            logoSrc={LogoImage}
            title="Hosting"
            logoStyle={logoStyle}
          />
          <Text
            content="Atento a nuestras redes sociales para nueva información de AGOBANK."
            {...textStyle}
          />

          <SocialList>
            {socialLinks.map((item) => (
              <li className={item.name} key={`link-key${item.id}`}>
                <a aria-label={item.name} href={item.link}>
                  {item.icon}
                </a>
              </li>
            ))}
          </SocialList>
          <Menu>
            {Data.saasClassicJson.FOOTER_WIDGET.map((widget, index) => (
              <MenuItem key={`footer-link${index}`}>
                <a href="/">{widget.title}</a>
              </MenuItem>
            ))}
          </Menu>
        </MenuArea>
        {/* <Box className="row" {...row}>
                    <Box {...colOne}>
                        <Logo
                            href="#"
                            logoSrc={LogoImage}
                            title="Hosting"
                            logoStyle={logoStyle}
                        />
                        <Text content="hello@redq.io" {...textStyle} />
                        <Text content="+479-443-9334" {...textStyle} />
                    </Box>
                    <Box {...colTwo}>
                        {Data.saasClassicJson.FOOTER_WIDGET.map(
                            (widget, index) => (
                                <Box
                                    className="col"
                                    {...col}
                                    key={`footer-widget-${index}`}
                                >
                                    <Heading
                                        content={widget.title}
                                        {...titleStyle}
                                    />
                                    <List>
                                        {widget.menuItems.map((item, index) => (
                                            <ListItem
                                                key={`footer-list-item-${index}`}
                                            >
                                                <a
                                                    href={item.url}
                                                    className="ListItem"
                                                >
                                                    {item.text}
                                                </a>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            )
                        )}
                    </Box>
                </Box> */}
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: [1, '35%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '65%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
    mb: '30px',
  },
  // Default logo size
  logoStyle: {
    maxWidth: ['180px', '190px'],
    mb: '100px',
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    mb: '10px',
    mt: '25px',
  },
};

export default Footer;
